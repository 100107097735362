<template>
	<v-card flat elevation="0" class="min-height-screen h-full">
		<div class="px-4 d-flex flex-column h-full w-full align-center justify-center">
			<v-avatar size="150" tile>
				<v-img :src="logoUrl" contain />
			</v-avatar>
			<transition name="fade" mode="out-in">
				<loader v-if="isLoading" />
				<v-form
					v-else-if="!paymentDone && !isLoading"
					ref="paymentForm"
					@submit.prevent="handleSubmit"
				>
					<stripe-element-payment
						ref="paymentRef"
						:pk="publishableKey"
						:elements-options="elementsOptions"
						:confirm-params="confirmParams"
						@error="() => (isSubmitting = false)"
						@element-ready="() => (elementLoaded = true)"
					/>
					<v-btn
						type="submit"
						large
						rounded
						class="mt-4 font-weight-bold"
						color="primary"
						:disabled="paymentBtnDisabled"
						:loading="paymentBtnLoading"
					>
						<span>Pay now</span>
					</v-btn>
				</v-form>
				<div v-else id="payment-message">{{ message }}</div>
			</transition>
		</div>
	</v-card>
</template>

<script>
/* eslint-disable indent */
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import AuthMixin from '@/mixins/auth'
import loadScript from '@/utils/load-script'
import Loader from '@/components/Loader.vue'
import { BASE_URL } from '@/constants'

const PUBLISHABLE_KEY = process.env.VUE_APP_STRIP_PUBLISHABLE_KEY

export default {
	name: 'CheckoutPage',
	mixins: [AuthMixin],
	components: {
		StripeElementPayment,
		Loader,
	},
	data() {
		return {
			isLoading: false,
			isSubmitting: false,
			paymentDone: false,
			elementLoaded: false,
			message: '',
			publishableKey: PUBLISHABLE_KEY,
			clientSecret: this.$route.query.client_secret,
			elementsOptions: {
				appearance: {
					theme: this.$vuetify.theme.dark ? 'night' : 'stripe',
				}, // appearance options
			},
			confirmParams: {
				return_url: `${BASE_URL}/payment/success?token=${this.$route.query.token}`, // success url
			},
		}
	},
	beforeRouteEnter(to, _from, next) {
		const clientSecret = to.query.client_secret
		if (typeof clientSecret !== 'string' || !clientSecret) {
			next((vm) => {
				vm.$router.push({ name: 'home', query: { token: vm.authToken } })
			})
			return
		}
		next()
	},
	created() {
		const loadStripeScriptAndPayment = async () => {
			this.isLoading = true
			await loadScript('https://js.stripe.com/v3/')

			const stripe = window.Stripe(PUBLISHABLE_KEY)

			if (stripe) {
				// Retrieve the PaymentIntent
				this.isLoading = true

				try {
					const { paymentIntent } = await stripe.retrievePaymentIntent(this.clientSecret)
					switch (paymentIntent.status) {
						case 'succeeded':
							this.message = 'Success! Payment was received.'
							this.paymentDone = true
							break
						case 'processing':
							this.message = "Payment processing. We'll update you when payment is received."
							this.paymentDone = true
							break
						default:
							// this.message = 'Something went wrong.'
							break
					}
				} catch (error) {
					this.message = 'Oops!! something went wrong'
				}
				this.isLoading = false
			}

			// this.isLoading = false
		}

		loadStripeScriptAndPayment()
	},
	mounted() {
		this.elementsOptions.clientSecret = this.clientSecret
	},
	computed: {
		paymentBtnDisabled() {
			// if (is loading) or (element payment card not loaded or ready) or (when payment submitting)
			return this.isLoading || !this.elementLoaded || this.isSubmitting
		},
		paymentBtnLoading() {
			// if (is loading) or (when payment submitting)
			return this.isLoading || this.isSubmitting
		},
		logoUrl() {
			return this.$vuetify.theme.dark ? '/logo-dark.png' : '/logo.png'
		},
	},
	methods: {
		async handleSubmit() {
			if (!this.elementLoaded || !this.clientSecret) return
			this.isSubmitting = true
			this.$refs.paymentRef.submit()
		},
	},
}
</script>
